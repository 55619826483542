import "./Home.css";
import { useEffect, useState } from "react";

function Home() {
  return (
    <div className="home__container">
      <div className="home__box"></div>
    </div>
  );
}

export default Home;
